import { default as indexk34Ke0hlTNMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/clip_library/[facility-id]/index.vue?macro=true";
import { default as indexrpNFzMhn9PMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/customer_clip/index.vue?macro=true";
import { default as manualjD5ucQJbiwMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/customer/manual.vue?macro=true";
import { default as index1rsfcc0nSaMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/index.vue?macro=true";
import { default as groupua0jxKjwy5Meta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/group.vue?macro=true";
import { default as indexPXIid0BrGtMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/index.vue?macro=true";
import { default as inviteLzk9cOOSp9Meta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/invite.vue?macro=true";
import { default as profilejc21IW9PGwMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/profile.vue?macro=true";
import { default as registerMZ19oobfVVMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/register.vue?macro=true";
import { default as indexq7mMoq0S4iMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/slide/preview_pdf/index.vue?macro=true";
import { default as indexEUbS5DNbMnMeta } from "/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/slide/preview/index.vue?macro=true";
export default [
  {
    name: indexk34Ke0hlTNMeta?.name ?? "clip_library-facilityid",
    path: indexk34Ke0hlTNMeta?.path ?? "/clip_library/:facilityid()",
    meta: indexk34Ke0hlTNMeta || {},
    alias: indexk34Ke0hlTNMeta?.alias || [],
    redirect: indexk34Ke0hlTNMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/clip_library/[facility-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrpNFzMhn9PMeta?.name ?? "customer_clip",
    path: indexrpNFzMhn9PMeta?.path ?? "/customer_clip",
    meta: indexrpNFzMhn9PMeta || {},
    alias: indexrpNFzMhn9PMeta?.alias || [],
    redirect: indexrpNFzMhn9PMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/customer_clip/index.vue").then(m => m.default || m)
  },
  {
    name: manualjD5ucQJbiwMeta?.name ?? "customer-manual",
    path: manualjD5ucQJbiwMeta?.path ?? "/customer/manual",
    meta: manualjD5ucQJbiwMeta || {},
    alias: manualjD5ucQJbiwMeta?.alias || [],
    redirect: manualjD5ucQJbiwMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/customer/manual.vue").then(m => m.default || m)
  },
  {
    name: index1rsfcc0nSaMeta?.name ?? "index",
    path: index1rsfcc0nSaMeta?.path ?? "/",
    meta: index1rsfcc0nSaMeta || {},
    alias: index1rsfcc0nSaMeta?.alias || [],
    redirect: index1rsfcc0nSaMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: groupua0jxKjwy5Meta?.name ?? "liff-liffid-group",
    path: groupua0jxKjwy5Meta?.path ?? "/liff/:liffid()/group",
    meta: groupua0jxKjwy5Meta || {},
    alias: groupua0jxKjwy5Meta?.alias || [],
    redirect: groupua0jxKjwy5Meta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/group.vue").then(m => m.default || m)
  },
  {
    name: indexPXIid0BrGtMeta?.name ?? "liff-liffid",
    path: indexPXIid0BrGtMeta?.path ?? "/liff/:liffid()",
    meta: indexPXIid0BrGtMeta || {},
    alias: indexPXIid0BrGtMeta?.alias || [],
    redirect: indexPXIid0BrGtMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/index.vue").then(m => m.default || m)
  },
  {
    name: inviteLzk9cOOSp9Meta?.name ?? "liff-liffid-invite",
    path: inviteLzk9cOOSp9Meta?.path ?? "/liff/:liffid()/invite",
    meta: inviteLzk9cOOSp9Meta || {},
    alias: inviteLzk9cOOSp9Meta?.alias || [],
    redirect: inviteLzk9cOOSp9Meta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/invite.vue").then(m => m.default || m)
  },
  {
    name: profilejc21IW9PGwMeta?.name ?? "liff-liffid-profile",
    path: profilejc21IW9PGwMeta?.path ?? "/liff/:liffid()/profile",
    meta: profilejc21IW9PGwMeta || {},
    alias: profilejc21IW9PGwMeta?.alias || [],
    redirect: profilejc21IW9PGwMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/profile.vue").then(m => m.default || m)
  },
  {
    name: registerMZ19oobfVVMeta?.name ?? "liff-liffid-register",
    path: registerMZ19oobfVVMeta?.path ?? "/liff/:liffid()/register",
    meta: registerMZ19oobfVVMeta || {},
    alias: registerMZ19oobfVVMeta?.alias || [],
    redirect: registerMZ19oobfVVMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/liff/[liff-id]/register.vue").then(m => m.default || m)
  },
  {
    name: indexq7mMoq0S4iMeta?.name ?? "slide-preview_pdf",
    path: indexq7mMoq0S4iMeta?.path ?? "/slide/preview_pdf",
    meta: indexq7mMoq0S4iMeta || {},
    alias: indexq7mMoq0S4iMeta?.alias || [],
    redirect: indexq7mMoq0S4iMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/slide/preview_pdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexEUbS5DNbMnMeta?.name ?? "slide-preview",
    path: indexEUbS5DNbMnMeta?.path ?? "/slide/preview",
    meta: indexEUbS5DNbMnMeta || {},
    alias: indexEUbS5DNbMnMeta?.alias || [],
    redirect: indexEUbS5DNbMnMeta?.redirect,
    component: () => import("/home/runner/work/medicalclip-frontend/medicalclip-frontend/medicalclip-frontend/src/pages/slide/preview/index.vue").then(m => m.default || m)
  }
]