export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('truncate', {
    mounted (el, binding) {
      nextTick(() => {
        const maxHeight = binding.value || 330
        const originalText = el.innerText.trim()

        function truncateText (element: HTMLElement) {
          element.innerText = originalText

          if (element.scrollHeight <= maxHeight) {
            return
          }

          while (element.scrollHeight > maxHeight && element.innerText.length > 0) {
            element.innerText = element.innerText.slice(0, -1).trim()
          }

          element.innerText = element.innerText.trim() + '...'
        }

        truncateText(el)
      })
    },
  })
})
